import React, { useEffect, useContext, useState } from "react"
import { Text } from "react-native"

import AuthContext from "../../../contexts/AuthContext"

import { Container, Circulo } from "./styles"

export default function EstadoUsuario() {
    const { usuario, situacao, setSituacao } = useContext(AuthContext)

    const values = {
        online: { color: "green", nome: "Online" },
        offline: { color: "red", nome: "Offline" },
        ausente: { color: "yellow", nome: "Ausente" }
    }

    useEffect(() => {
        if (usuario) {
            let final = "offline"

            for (let setor of usuario.setores) {
                if (final == "offline" && setor.situacao == "ausente") final = "ausente"
                if (setor.situacao == "online") final = "online"
            }

            setSituacao(final)
        }
    }, [usuario])

    return (
        <Container>
            <Circulo style={{ background: situacao ? values[situacao].color : "#000000", marginRight: 10 }} />
            <Text>{situacao ? values[situacao].nome : "Carregando..."}</Text>
        </Container>
    )
}