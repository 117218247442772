import React, { useState } from "react"
import { View, Text } from "react-native"

import { Container, Atalho } from "./styles"

export default function Atalhos({ mensagem, setMensagem, setShowAtalhos }) {

    const handleClickAtalho = (atalho) => {
        setShowAtalhos(false)
        setMensagem(atalho.mensagem)
    }

    const [atalhos] = useState([
        { nome: "Mensagem de boas-vindas", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla blabla blabla", atalho: "boas-vindas" },
        { nome: "Teste 1", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla", atalho: "teste1" },
        { nome: "Teste 2", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla blabla bla", atalho: "teste2" },
        { nome: "Teste 3", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla bla", atalho: "teste3" },
        { nome: "Teste 4", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla blabla bla", atalho: "teste4" },
        { nome: "Teste 5", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla", atalho: "teste5" },
        { nome: "Teste 6", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla", atalho: "teste6" },
        { nome: "Teste 7", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla", atalho: "teste7" },
        { nome: "Teste 8", mensagem: "Blabla bla blabla blabla blabla blabla blabla blabla blabla", atalho: "teste8" },
    ])

    return (
        <Container>
            {atalhos.filter(a => a.atalho.indexOf(mensagem.replace("/", "")) >= 0).map((atalho, index) => (
                <Atalho onPress={() => handleClickAtalho(atalho)} key={index}>
                    <View>
                        <Text style={{ fontSize: 16, fontWeight: 500 }}>{atalho.nome}</Text>
                        <Text style={{ fontSize: 14 }}>{atalho.mensagem}</Text>
                    </View>
                    <Text style={{ fontSize: 12 }}>/{atalho.atalho}</Text>
                </Atalho>
            ))}
        </Container>
    )
}