import api from "./apiService"

const getCamposAdicionais = async () => {
    try {
        const resultado = await api.get(`/campos-adicionais`)
        const campos = resultado.data
        return campos
    } catch (error) {
        throw error
    }
}
export { getCamposAdicionais }