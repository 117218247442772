import React from 'react'

import { AppProvider } from '../contexts/AppContext'
import { ChatProvider } from '../contexts/ChatContext'
import { AuthProvider } from '../contexts/AuthContext'
import { AtendimentoProvider } from '../contexts/AtendimentoContext'


const Providers = ({ children }) => {
    return (
        <AppProvider>
            <AuthProvider>
                <AtendimentoProvider>
                    <ChatProvider>
                        {children}
                    </ChatProvider>
                </AtendimentoProvider>
            </AuthProvider>
        </AppProvider>
    )
}

export default Providers