import React, { useContext, useEffect } from "react"
import { View, TouchableOpacity, Text } from "react-native"
import Icon from 'react-native-vector-icons/FontAwesome5';
import AppContext from "../../contexts/AppContext";

import { Container, AlertWrapper, AlertBody, AlertButtons, AlertButton } from "./styles"

export default function Alert() {
    const { alertShow, setAlertShow } = useContext(AppContext)

    const buttonColors = {
        cancel: { backgroundColor: "#dc3545", color: "#ffffff" },
        confirm: { backgroundColor: "#198754", color: "#ffffff" },
        primary: { backgroundColor: "#0d6efd", color: "#ffffff" },
    }

    const icons = {
        success: { name: "check-circle", color: "#198754" },
        warning: { name: "exclamation-triangle", color: "#ffc107" },
        danger: { name: "times", color: "#dc3545" }
    }

    useEffect(() => {
        console.log("MODAL SHOW", alertShow)
    }, [alertShow])

    return (
        <>
            {alertShow && <Container>
                <View style={{ flexDirection: "column", alignItems: "flex-end", justifyContent: "center" }}>
                    <AlertWrapper>
                        <AlertBody>
                            {(alertShow.type && icons[alertShow.type]) && <View style={{ padding: 20, textAlign: "center" }}>
                                <Icon name={icons[alertShow.type].name} size={64} color={icons[alertShow.type].color} />
                            </View>}
                            {alertShow.title && <Text style={{ fontSize: 20, fontWeight: 500, color: "#8C98BB", textAlign: "center" }}>{alertShow.title}</Text>}
                            {alertShow.text && <Text style={{ fontSize: 16, fontWeight: 300, color: "#8C98BB", textAlign: "center", marginTop: 10 }}>{alertShow.text}</Text>}
                            {alertShow.content}
                        </AlertBody>
                        <AlertButtons>
                            {alertShow?.buttons.map((button, index) => (
                                <AlertButton
                                    style={{
                                        ...{ borderBottomLeftRadius: index == 0 ? 5 : 0 },
                                        ...{ borderBottomRightRadius: index == alertShow.buttons.length - 1 ? 5 : 0 },
                                        ...buttonColors[button.type],
                                        ...button.style
                                    }}
                                    onPress={() => {
                                        if (button.onPress) button.onPress()
                                        setAlertShow(null)
                                    }}
                                    key={index}
                                >
                                    <Text style={{ textAlign: "center", fontSize: 16, fontWeight: 500, color: "#ffffff" }}>{button.text}</Text>
                                </AlertButton>
                            ))}
                        </AlertButtons>
                    </AlertWrapper>
                </View>
            </Container>}
        </>
    )
}