import React, { useRef, useState, useContext } from "react"
import { View, Text, Button } from 'react-native'

import * as Yup from 'yup'

import { Form } from '@unform/mobile'
import Input from '../Form/input'

import { doLogin } from '../../services/authService'

import styles from "./styles"
import AuthContext from "../../contexts/AuthContext"

export default function LoginForm() {
    const formRef = useRef()
    const { setLogged, setSessionToken } = useContext(AuthContext)

    const [loading, setLoading] = useState(false)

    const [errorMessage, setErrorMessage] = useState(null)

    const handleSubmit = async (data) => {
        try {
            setLoading(true)

            let shape = {
                email: Yup.string().email("E-mail inválido").required("Campo obrigatório"),
                senha: Yup.string().required("Campo obrigatório")
            }

            const schema = Yup.object().shape(shape)

            await schema.validate(data, {
                abortEarly: false
            })

            formRef.current.setErrors({})

            console.log("DADOS DE LOGIN", data)

            let resultado = await doLogin(data)

            console.log("RESULT", resultado)

            let { access_token } = resultado

            setSessionToken(access_token)
            setLogged(true)

            setLoading(false)
        } catch (error) {
            console.error("ERROS", error)

            if (error instanceof Yup.ValidationError) {
                const errorMessages = {}

                error.inner.forEach(err => {
                    errorMessages[err.path] = err.message
                })

                formRef.current.setErrors(errorMessages)
            } else {
                setErrorMessage(error.data || "Ocorreu um erro")
            }

            setLoading(false)
        }
    }

    return (
        <View style={styles.container}>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <Text style={styles.title}>Seja bem-vindo</Text>
                <Text style={styles.subtitle}>Entre com sua conta para continuar</Text>

                <Input name="email" label="E-mail" placeholder="Digite seu e-mail" value="renegustavocegoncalves@gmail.com" />
                <Input name="senha" label="Senha" placeholder="Digite sua senha" value="Teste.123" secureTextEntry={true} />

                <Button title={loading ? "Entrando" : "Entrar"} style={styles.button} onPress={() => formRef.current.submitForm()} disabled={loading} />
            </Form>

            {errorMessage && <View>
                <Text style={styles.errorMessage}>{errorMessage}</Text>
            </View>}
        </View>
    )
}