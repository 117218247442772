import React from "react"

import { Text, View } from "react-native"
import Avatar from "../Avatar"

import { } from "./styles"

export default function Contato({ contato }) {
    return (
        <View style={{ flexDirection: "column", alignItems: "center" }}>
            <Avatar src={contato.foto} size={100} />
            <Text style={{ fontSize: 18, marginTop: 20 }}>{contato.nome || "Cliente sem nome"}</Text>

            <View style={{ borderRadius: 5, borderWidth: 1, borderColor: "#eeeeee", marginTop: 20, padding: 10, width: "100%" }}>
                {contato?.canais.length == 0 && <Text style={{ textAlign: "center" }}>Nenhum canal</Text>}
                {contato?.canais.map((canal, index) => (
                    <View style={{ flexDirection: "row" }} key={index}>
                        <Text style={{ fontWeight: 600, marginRight: 10 }}>{campo.nome}:</Text>
                        <Text>{campo.valor || "-"}</Text>
                    </View>
                ))}
            </View>

            <View style={{ borderRadius: 5, borderWidth: 1, borderColor: "#eeeeee", marginTop: 20, padding: 10, width: "100%" }}>
                {contato?.campos_adicionais.length == 0 && <Text style={{ textAlign: "center" }}>Nenhum campo adicional</Text>}
                {contato?.campos_adicionais.map((campo, index) => (
                    <View style={{ flexDirection: "row" }} key={index}>
                        <Text style={{ fontWeight: 600, marginRight: 10 }}>{campo.nome}:</Text>
                        <Text>{campo.valor || "-"}</Text>
                    </View>
                ))}
            </View>
        </View>
    )
}