import styled from "styled-components/native"

export const Container = styled.View`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Imagem = styled.Image`
    width: 100px;
    height: 100px;
    margin-top: 10px;
`