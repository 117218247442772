import React, { useRef } from "react"

import { useHover } from 'react-native-web-hooks';

import { Container, Dropdown } from "./styles"

export default function MenuLink({ children, onHover, dropdown }) {
    const ref = useRef(null)

    const isHovered = useHover(ref);

    return (
        <Container ref={ref} style={isHovered ? { background: "#eeeeee" } : {}}>
            {children}

            {(onHover && isHovered) && <>{onHover}</>}
            {(dropdown && isHovered) && <Dropdown>{dropdown}</Dropdown>}
        </Container>
    )
}