import React, { useContext, useState, useEffect } from "react"
import { View } from "react-native"

import Icon from 'react-native-vector-icons/FontAwesome5';

import styles, { Wrapper, BottomMenu, BottomMenuLink } from "./styles"
import AppContext from "../../../../contexts/AppContext"

import Fila from "./Fila"
import Contatos from "./Contatos";

export default function OpcoesColuna() {
    const [botaoSelecionado, setBotaoSelecionado] = useState("fila")

    const botoes = [
        { id: "fila", nome: "Fila", icone: "comments" },
        { id: "contatos", nome: "Contatos", icone: "users" }
    ]

    return (
        <Wrapper>
            <View style={{ flex: 1 }}>
                {botaoSelecionado == "fila" && <Fila />}
                {botaoSelecionado == "contatos" && <Contatos />}
            </View>

            <BottomMenu>
                {botoes.map(botao => (
                    <BottomMenuLink key={botao.id} onPress={() => { setBotaoSelecionado(botao.id) }}>
                        <Icon name={botao.icone} size={32} color={botaoSelecionado == botao.id ? "#8C98BB" : "#ccc"} />
                    </BottomMenuLink>
                ))}
            </BottomMenu>
        </Wrapper>
    )
}