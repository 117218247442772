import api from "./apiService"

const getContatos = async () => {
    try {
        const resultado = await api.get(`/contatos`)
        const contatos = resultado.data
        return contatos
    } catch (error) {
        throw error
    }
}

const postContatos = async (data) => {
    try {
        const resultado = await api.post(`/contatos`, data)
        const contato = resultado.data
        return contato
    } catch (error) {
        throw error
    }
}

export { getContatos, postContatos }