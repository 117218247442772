import React, { useRef, useEffect, useState, useContext } from "react"
import { View } from "react-native"
import Button from "../Button"
import { Form } from '@unform/mobile'

import * as Yup from 'yup'

import Input from "../forms/Input"

import api from "../../services/apiService"

import AtendimentoContext from "../../contexts/AtendimentoContext"

export default function TransferenciaSetor() {
    const formRef = useRef(null)

    const { atendimentoAberto } = useContext(AtendimentoContext)

    const [setores, setSetores] = useState([])
    const [setoresOptions, setSetoresOptions] = useState([])

    useEffect(() => {
        buscarSetores()
    }, [])

    const handleSubmit = async (data) => {
        try {
            console.log("handleSubmit", data)

            const schema = Yup.object().shape({
                setor_id: Yup.string()
                    .required("Campo obrigatório")
            })

            await schema.validate(data, {
                abortEarly: false
            })

            formRef.current.setErrors({})
        } catch (error) {
            console.error("ERRO", error)
            if (error instanceof Yup.ValidationError) {
                const errorMessages = {}

                error.inner.forEach(err => {
                    errorMessages[err.path] = err.message
                })

                formRef.current.setErrors(errorMessages)
            }
        }
    }

    const buscarSetores = async () => {
        try {
            let resultado = await api.get("setores")
            console.log("RESULTADO buscarSetores", resultado)
            setSetores(resultado.data)

            let options = []
            for (let setor of resultado.data.filter(s => s.id != atendimentoAberto.setor_id)) {
                options.push({ label: setor.nome, value: setor.id })
            }
            setSetoresOptions(options)
        } catch (error) {
            console.error("ERRO buscarSetores", error)
        }
    }

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <Input name="setor_id" type="select" label="Setor" options={setoresOptions} />
            <Input name="mensagem" type="textarea" label="Mensagem para o próximo atendente" numberOfLines={5} onChange={(e) => console.log(e.target.value)} />

            <Button style={{ alignSelf: "flex-end", marginTop: 20 }} onPress={() => formRef.current.submitForm()}>Confirmar</Button>
        </Form >
    )
}