import { StyleSheet } from 'react-native'
import styled from "styled-components/native"

export const Container = styled.View`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const ModalWrapper = styled.View`
    margin-top: 10px;
    background: #ffffff;
    border-radius: 5px;
`

export const ModalHeader = styled.View`
    padding: 20px;
    border-bottom: 1px solid #eeeeee;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const ModalBody = styled.View`
    padding: 20px;
`

const styles = StyleSheet.create({
    sm: {
        width: 300,
        maxWidth: "90vw"
    },
    md: {
        width: 500,
        maxWidth: "90vw"
    },
    lg: {
        width: 800,
        maxWidth: "90vw"
    }
});

export default styles