import React from "react"
import { View } from "react-native"
import Alert from "../../components/Alert"

import Header from "../../components/Header"
import ImageViewer from "../../components/ImageViewer"
import Modal from "../../components/Modal"
import Atendimento from "./Atendimento"

import { Container } from "./styles"

export default function Main() {
    return (
        <Container>
            <Header />

            <Atendimento />

            <ImageViewer />
            <Modal />
            <Alert />
        </Container>
    )
}