import React, { useState, useContext, useEffect } from "react"
import { ScrollView, View, Text } from "react-native"
import Avatar from "../../../../../components/Avatar"
import Contato from "../../../../../components/Contato"
import AppContext from "../../../../../contexts/AppContext"

import { getContatos } from "../../../../../services/contatosService"

import { ContatoCard } from "./styles"

export default function Contatos() {
    const { setModalShow } = useContext(AppContext)

    const [contatos, setContatos] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        buscarContatos()
    }, [])

    const buscarContatos = async () => {
        try {
            setLoading(true)

            let resultado = await getContatos()

            console.log("CONTATOS", resultado)

            setContatos(resultado)

            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const abrirContato = (contato) => {
        setModalShow({
            size: "md",
            content: <Contato contato={contato} />,
            title: contato.nome || "Contato"
        })
    }

    return (
        <ScrollView style={{ padding: 10 }}>
            {contatos.map(contato => (
                <ContatoCard key={contato.id} onPress={() => abrirContato(contato)}>
                    <Avatar src={contato.foto || null} size={64} />
                    <View style={{ paddingLeft: 10 }}>
                        <Text style={{ fontSize: 16 }}>{contato.nome}</Text>
                    </View>
                </ContatoCard>
            ))}
        </ScrollView>
    )
}