import api from "./apiService"

const getAtendimentos = async () => {
    try {
        const resultado = await api.get(`/atendimentos/sessao`)
        const setores = resultado.data
        return setores
    } catch (error) {
        throw error
    }
}

const atenderAtendimento = async (atendimento_id) => {
    try {
        const resultado = await api.post(`/atendimentos/atender`, { atendimento_id })
        const atendimento = resultado.data
        return atendimento
    } catch (error) {
        throw error
    }
}

const encerrarAtendimento = async (atendimento_id) => {
    try {
        const resultado = await api.post(`/atendimentos/encerrar`, { atendimento_id })
        //const atendimento = resultado.data
        return true
    } catch (error) {
        throw error
    }
}

export { getAtendimentos, atenderAtendimento, encerrarAtendimento }