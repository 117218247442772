import React, { useContext, useState } from "react"
import { TouchableOpacity, ScrollView, View, Text, Dimensions } from "react-native"

import Icon from 'react-native-vector-icons/FontAwesome5';
import AppContext from "../../../../../contexts/AppContext";
import AtendimentoContext from "../../../../../contexts/AtendimentoContext";
import ChatContext from "../../../../../contexts/ChatContext";

import { Container, Button, InputSearch } from "./styles"

import TransferenciaSetor from "../../../../../components/TransferenciaSetor"

export default function ChatHeader() {
    const { setModalShow, setAlertShow } = useContext(AppContext)
    const { atendimentoAberto, setAtendimentoAberto, excluirAtendimento } = useContext(AtendimentoContext)
    const { pesquisa, setPesquisa, opcoesColunaShow, setOpcoesColunaShow, sessaoColunaShow, setSessaoColunaShow } = useContext(ChatContext)

    const [buscaAberta, setBuscaAberta] = useState(false)

    const encerrar = async () => {

        setAlertShow({
            type: "warning",
            title: "Atenção!",
            text: "Deseja encerrar o atendimento?",
            buttons: [
                { type: "cancel", text: "Não" },
                {
                    type: "confirm", text: "Sim", onPress: () => {
                        setAtendimentoAberto(null)
                        excluirAtendimento(atendimentoAberto)
                    }
                },
            ]
        })
    }

    const abrirTransferencia = async () => {
        setModalShow({
            size: "md",
            content: <TransferenciaSetor />,
            title: "Transferir atendimento"
        })
    }

    return (
        <Container>
            {Dimensions.get('window').width > 600 && <View style={{ borderRightWidth: 1, borderRightColor: "#eeeeee", height: "100%", justifyContent: "center", alignItems: "center", paddingRight: 10 }}>
                <TouchableOpacity style={{ marginHorizontal: 10 }} onPress={() => { setOpcoesColunaShow(!opcoesColunaShow) }}>
                    <Icon name={opcoesColunaShow ? "chevron-left" : "chevron-right"} size={24} color="#8C98BB" />
                </TouchableOpacity>
            </View>}
            <ScrollView>
                <View style={{ flexDirection: "row", flex: 1, alignItems: "center" }}>
                    <View style={{ flexDirection: "row", flex: 1 }}>
                        {!buscaAberta ? <TouchableOpacity style={{ marginHorizontal: 20 }} onPress={() => { setBuscaAberta(true) }}>
                            <Icon name="search" size={24} color="#8C98BB" />
                        </TouchableOpacity> :
                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <InputSearch onChange={e => setPesquisa(e.target.value)} placeholder="Pesquisar..." />
                                <TouchableOpacity style={{ marginHorizontal: 20 }} onPress={() => {
                                    setPesquisa("")
                                    setBuscaAberta(false)
                                }}>
                                    <Icon name="times" size={18} color="#8C98BB" />
                                </TouchableOpacity>
                            </View>
                        }
                    </View>
                    <View style={{ flexDirection: "row", flex: 1, justifyContent: "flex-end", alignItems: "center" }}>
                        <TouchableOpacity style={{ marginHorizontal: 20 }} onPress={abrirTransferencia}>
                            <Icon name="random" size={24} color="#8C98BB" />
                        </TouchableOpacity>
                        <Button style={{ alignSelf: "flex-end" }} onPress={encerrar}><Text style={{ color: "#ffffff" }}>Encerrar</Text></Button>
                        <TouchableOpacity style={{ marginRight: 20 }} onPress={() => { setAtendimentoAberto(null) }}>
                            <Icon name="times" size={24} color="#8C98BB" />
                        </TouchableOpacity>
                    </View>
                </View>
            </ScrollView>
            <View style={{ borderLeftWidth: 1, borderLeftColor: "#eeeeee", height: "100%", justifyContent: "center", alignItems: "center", paddingLeft: 10 }}>
                <TouchableOpacity style={{ marginHorizontal: 10 }} onPress={() => { setSessaoColunaShow(!sessaoColunaShow) }}>
                    <Icon name={sessaoColunaShow ? "chevron-right" : "chevron-left"} size={24} color="#8C98BB" />
                </TouchableOpacity>
            </View>
        </Container>
    )
}