import { StyleSheet } from 'react-native'
import styled from "styled-components/native"

const styles = StyleSheet.create({
    enviado: {
        background: "red"
    },
    recebido: {
        background: "green"
    }
})

export default styles

export const Container = styled.View`
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`

export const Imagem = styled.Image`
    min-width: 100px;
    min-height: 100px;
    max-width: 300px;
    max-height: 300px;
    margin: 10px;
`

export const Arquivos = styled.View`
    display: flex;
    flex-direction: row;
`

export const Arquivo = styled.View`
    width: 300px;
    height: 80px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    margin-left: 10px;
`

export const Icone = styled.View`
    height: 60px;
    width: 60px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    background-color: #eeeeee;
    margin-right: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
`