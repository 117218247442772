import React, { useState, useEffect, useContext } from "react"

import { View, ScrollView, Text } from "react-native"
import Avatar from "../../Avatar"

import Input from "../../forms/Input"

import { ContatoCard } from "./styles"

import { getContatos } from "../../../services/contatosService"
import AppContext from "../../../contexts/AppContext"

export default function PesquisaContato() {
    const { setAlertShow } = useContext(AppContext)

    const [contatos, setContatos] = useState([])

    const [pesquisa, setPesquisa] = useState("")

    useEffect(() => {
        buscarContatos()
    }, [])

    const buscarContatos = async () => {
        try {
            let resultado = await getContatos()

            console.log("CONTATOS", resultado)

            setContatos(resultado)
        } catch (error) {

        }
    }

    const selecionarContato = (contato) => {
        setAlertShow({
            type: "warning",
            title: "Atenção!",
            content: (
                <View>
                    <Text style={{ fontSize: 16 }}>Deseja direcionar para este contato?</Text>

                    <View style={{ borderRadius: 5, borderWidth: 1, borderColor: "#eeeeee", marginTop: 20, padding: 10 }}>
                        <View style={{ flexDirection: "row" }}>
                            <Text style={{ fontWeight: 600, marginRight: 10 }}>Nome:</Text>
                            <Text>{contato.nome}</Text>
                        </View>
                        {contato?.campos_adicionais.map((campo, index) => (
                            <View style={{ flexDirection: "row" }} key={index}>
                                <Text style={{ fontWeight: 600, marginRight: 10 }}>{campo.nome}:</Text>
                                <Text>{campo.valor || "-"}</Text>
                            </View>
                        ))}
                    </View>
                </View>
            ),
            buttons: [
                { type: "cancel", text: "Não" },
                {
                    type: "confirm", text: "Sim", onPress: () => {
                        alert("Em desenvolvimento")
                    }
                },
            ]
        })
    }

    return (
        <>
            <Input placeholder="Pesquisar contato..." value={pesquisa} onChange={e => setPesquisa(e.target.value)} />

            <ScrollView style={{ maxHeight: 250 }}>
                {contatos.length == 0 && <Text style={{ textAlign: "center", marginVertical: 20, fontSize: 14 }}>Nenhum contato encontrado</Text>}
                {contatos.map(contato => (
                    <ContatoCard key={contato.id} onPress={() => selecionarContato(contato)}>
                        <Avatar src={contato.foto || null} size={32} />
                        <View style={{ paddingLeft: 10 }}>
                            <Text style={{ fontSize: 16 }}>{contato.nome}</Text>
                        </View>
                    </ContatoCard>
                ))}
            </ScrollView>
        </>
    )
}