import { StyleSheet } from 'react-native'
import styled from "styled-components/native"

export const Container = styled.View`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const AlertWrapper = styled.View`
    margin-top: 10px;
    background: #ffffff;
    border-radius: 5px;
    min-width: 300px;
    max-width: 90vw;
`

export const AlertBody = styled.View`
    padding: 30px;
`

export const AlertButtons = styled.View`
    flex-direction: row;
`

export const AlertButton = styled.TouchableOpacity`
    flex: 1;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
`