import axios from "axios";
require('dotenv').config()

export const api = axios.create({
    baseURL: "http://localhost:3030",//process.env.API_URL,
    params: {
        public_key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjaGF2ZV9wdWJsaWNhIjoiNTY2ZjEyZWQtOTNjOS00ZjNiLWJmMTAtZGU0M2IyMTU0MDg1In0.2lsUm5RL9lANvHSxwQ8R-GBr0MLPKHtjDRFZABtos94"
    }
})


api.interceptors.request.use(config => {
    const access_token = localStorage.getItem("access_token")
    if (access_token)
        config.headers["x-access-token"] = access_token

    return config
})

api.interceptors.response.use(resp => {
    return resp
}, (error) => {
    throw error.response
})

export default api;
