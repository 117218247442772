import React, { useState, createContext } from 'react'

const AppContext = createContext({})

export const AppProvider = ({ children }) => {
    const [imageViewerShow, setImageViewerShow] = useState(null)
    const [modalShow, setModalShow] = useState(null)
    const [alertShow, setAlertShow] = useState(/*{
        type: "danger",
        title: "Título",
        text: "Exemplo exemplo exemplo exemplo exemplo exemplo",
        buttons: [
            { type: "cancel", text: "Não", style: { background: "#dc3545", color: "#ffffff" } },
            //{ text: "OK", style: { background: "#0d6efd" } },
            { type: "confirm", text: "Sim", onPress: () => { return } },
        ]
    }*/null)

    return (
        <AppContext.Provider value={{
            imageViewerShow, setImageViewerShow,
            modalShow, setModalShow,
            alertShow, setAlertShow
        }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext
