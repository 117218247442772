import React, { useRef, useEffect } from "react"
import { View, Text } from "react-native"

import { Select } from "../../../styles/Input"

import { Input as TextInput } from "../../../styles/Input"

import { useField } from '@unform/core'

export default function Input({ name, label, type = "text", options, ...rest }) {
    const inputRef = useRef(null)
    const { fieldName, registerField, defaultValue, error } = name ? useField(name) : {}

    useEffect(() => {
        if (name) {
            registerField({
                name: fieldName,
                ref: inputRef.current,
                path: 'value'
            })
        }
    }, [fieldName, registerField])

    return (
        <View>
            {label && <Text style={{ fontSize: 14, paddingVertical: 10 }}>{label}</Text>}
            <View>
                {["text", "textarea"].includes(type) &&
                    <TextInput
                        ref={inputRef}
                        name={name}
                        multiples={type == "textarea"}
                        defaultValue={defaultValue}
                        {...rest}
                    />
                }

                {["select"].includes(type) &&
                    <Select
                        ref={inputRef}
                        name={name}
                        defaultValue={defaultValue}
                        {...rest}
                    >
                        {options?.map((option, index) => (
                            <Select.Item label={option.label} value={option.value} key={index} />
                        ))}
                    </Select>
                }
            </View>
            {error && <Text style={{ marginTop: 10, color: "red", fontSize: 12 }}>{error}</Text>}
        </View>
    )
}