import { StyleSheet } from 'react-native'
import styled from "styled-components/native"

const styles = StyleSheet.create({
    usuario: {
        fontSize: 15,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        height: "100%",
        position: "relative"
    },
    avatar: {
        width: 30,
        height: 30,
        borderRadius: "100%",
        marginRight: 5
    }


    /*

        position: absolute;
        background-color: #ffffff;
        bottom: -35;
        min-width: 100px;
        border-radius: 3;

        shadow-color: #000000;
        shadow-offset: { width: 0, height: 4 };
        shadow-opacity: 0.1;
        shadow-radius: 4.65;
    */
});

export default styles

export const Menu = styled.View`
    background-color: #ffffff;
    height: 70;
    border-radius: 3;
    width: "100%";
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    shadow-opacity: 0.2;
    shadow-radius: 5px;
    shadow-color: #000000;
    shadow-offset: -5px 5px;
    elevation: 8;
    z-index: 3;
`

export const Link = styled.TouchableOpacity`
    padding: 10px;
`

export const Separador = styled.View`
    height: 1px;
    width: 100%;
    background: #eeeeee;
`