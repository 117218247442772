import styled from "styled-components/native"

export const Input = styled.TextInput`
    padding: 10px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
`

export const Select = styled.Picker`
    padding: 10px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
`