import React, { useContext, useRef } from "react"
import { View, Image, Text } from "react-native"

import Icon from 'react-native-vector-icons/FontAwesome5';

import AuthContext from "../../contexts/AuthContext"

import { doLogout } from "../../services/authService"

import styles, { Menu, Link, Separador } from "./styles"

import MenuLink from "./MenuLink"
import EstadoUsuario from "./EstadoUsuario"
import EstadoUsuarioDropdown from "./EstadoUsuarioDropdown"

export default function Header() {
    const { usuario, setSessionToken, setLogged } = useContext(AuthContext)

    const logout = () => {
        doLogout()
        setSessionToken(null)
        setLogged(false)
    }

    const DropdownUsuario = () => {
        return (
            <>
                <Link><Text>Meus Dados</Text></Link>
                <Separador />
                <Link onPress={logout}><Text>Sair</Text></Link>
            </>
        )
    }

    return (
        <Menu>
            <MenuLink dropdown={<EstadoUsuarioDropdown />}>
                <EstadoUsuario />
            </MenuLink>
            <MenuLink dropdown={<DropdownUsuario />}>
                <Image source={{ uri: usuario?.foto }} style={styles.avatar} />
                <Text>{usuario?.nome}</Text>
                <Icon name="chevron-down" color="#000000" style={{ marginLeft: 10 }} />
            </MenuLink>
        </Menu>
    )
}