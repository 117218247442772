const datetime = require("./datetimeService")
const moment = require("moment")

export const formatDiferencaDatas = (dataIni, dataFim) => {
    let diferenca = datetime.calcularDiferencaDatas(dataIni, dataFim, "seconds")

    let passado = true
    let retorno = "Há "

    if (diferenca < 0) {
        passado = false
        diferenca *= -1
        retorno = "Daqui "
    }

    if (diferenca == 0)
        return "Agora"
    if (diferenca < 60) // 1 minuto
        return `${retorno} alguns segundos`
    if (diferenca < 120) // 2 minutos
        return `${retorno} 1 minuto`
    if (diferenca < 3600) // 1 hora
        return `${retorno} ${(diferenca / 60).toFixed(0)} minutos`
    if (diferenca < 7200) // 2 horas
        return `${retorno} 1 hora`
    if (diferenca < 86400) // 2 horas
        return `${retorno} ${(diferenca / 3600).toFixed(0)} horas`
    if (diferenca < 172800) // 2 dias
        return `${retorno} 1 dia`
    if (diferenca < 2592000) // 2 dias
        return `${retorno} ${(diferenca / 86400).toFixed(0)} dias`
    return `${passado ? "Há" : "Daqui"} algum tempo`
}

export const formatDataHora = (datahora, format) => {
    return moment(datahora).format(format)
}

export const formatHtmlToString = (html) => {
    if (!html) return html
    ["div", "strong"].forEach(tag => {
        html = html.split(`<${tag}>`).join("").split(`</${tag}>`).join("")
    })

    return html
}