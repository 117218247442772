import styled from "styled-components/native"

export const Wrapper = styled.ScrollView`
    flex: 1;
    height: 100%;
    background: #ffffff;
    shadow-opacity: 0.2;
    shadow-radius: 5px;
    shadow-color: #000000;
    shadow-offset: -5px 5px;
    elevation: 1;
    z-index: 2;
`

export const CardTitle = styled.Text`
    font-size: 14px;
    color: #545A60;
    margin-left: 5%;
    margin-bottom: 5px;
`

export const Card = styled.View`
    padding: 0 10px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 5px;
    border: 1px solid #D3D4D9;
    position: relative;
`

export const Icone = styled.Image`
    width: 25px;
    height: 25px;
`

export const BotaoAdicionar = styled.TouchableOpacity`
    background: #D3D4D9;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
`