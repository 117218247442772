import React, { useEffect, useRef, useState } from 'react'
import { TextInput, Text, View, TouchableOpacity } from 'react-native'
import { useField } from '@unform/core'
import Colors from '../../../values/colors'
import styles from './styles'
import Icon from 'react-native-vector-icons/FontAwesome5';

const Input = ({ name, label, styleInput, styleView, styleInputView, styleText, styleError, labelType, secureTextEntry = false, ...rest }) => {
    if (!labelType) labelType = "flat" // flat, outlined

    const inputRef = useRef(null)
    const { fieldName, registerField, defaultValue, error } = useField(name)
    const [isFocus, setIsFocus] = useState(false)

    const [showPassword, setShowPassword] = useState(true)

    const setFocus = (bool) => {
        setIsFocus(bool)
    }

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        })
    }, [fieldName, registerField])

    return (
        <View style={styleView}>
            {label && labelType == "flat" && <Text
                style={[styles.label, styleText]}
            >{label}</Text>}

            <View style={[styles.textInputView, error || (error && isFocus) ? { borderColor: Colors.error } : { marginBottom: 8 }, isFocus ? { borderColor: Colors.primary, borderWidth: 2 } : {}, styleInputView]}>
                {label && labelType == "outlined" && <Text
                    style={[styles.labelInner, styleText]}
                >{label}</Text>}

                <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <TextInput ref={inputRef} defaultValue={defaultValue} {...rest}
                        onFocus={() => { setFocus(true) }}
                        onBlur={() => { setFocus(false) }}
                        style={[styles.textInput, isFocus ? styles.textInput_focus : {}, labelType == "outlined" && isFocus ? { paddingTop: 15 } : {}, styleInput]}
                        secureTextEntry={secureTextEntry && showPassword} />

                    {secureTextEntry && <TouchableOpacity onPress={() => setShowPassword(!showPassword)}>
                        <Icon name={showPassword ? "eye" : "eye-slash"} size={16} color="#000000" style={{ marginHorizontal: 10 }} />
                    </TouchableOpacity>}
                </View>
            </View>

            {error &&
                <View>
                    <Text style={[{ color: Colors.error, fontSize: 12, marginTop: 5, marginBottom: 10 }, styleError]}>{error}</Text>
                </View>
            }
        </View>
    )
}

export default Input