import React, { useContext, useState, useEffect } from "react"
import { View, TouchableOpacity, Text, Alert } from "react-native"

import AvatarCanal from "../../../../../components/AvatarCanal"
import AtendimentoContext from "../../../../../contexts/AtendimentoContext"
import AuthContext from "../../../../../contexts/AuthContext"

import { formatDiferencaDatas } from "../../../../../services/formatService"
import { atenderAtendimento } from "../../../../../services/atendimentoService"

import styles, { ResumoConversa, Nav, NavItem, NavItemText, ContadorAtendimentos } from "./styles"
import AppContext from "../../../../../contexts/AppContext"

export default function Fila() {
    const { setAlertShow } = useContext(AppContext)
    const { atendimentos, loadingAtendimentos, atendimentoAberto, setAtendimentoAberto, getAtendimento } = useContext(AtendimentoContext)
    const { usuario } = useContext(AuthContext)
    const [navSelected, setNavSelected] = useState("fila")
    const [atendimentosFiltrados, setAtendimentosFiltrados] = useState([])

    useEffect(() => {
        setAtendimentosFiltrados((atendimentosFiltrados) => {
            console.log("{ setAtendimentosFiltrados }", atendimentos, atendimentosFiltrados, navSelected)
            return atendimentos.filter(a => {
                if (navSelected == "fila" && a.atendentes.filter(at => at.atendido == 1 && at.status == 1).length == 0) return true
                if (navSelected == "atendendo" && a.atendentes.filter(at => at.usuario_id == usuario?.id && at.atendido == 1 && at.status == 1).length > 0) return true
                return false
            })
        })
    }, [navSelected, loadingAtendimentos, atendimentos])

    const handleClickAtendimentoResumo = async (atendimento) => {
        let busca = getAtendimento(atendimento.id)

        if (busca?.id == atendimentoAberto?.id)
            return

        console.log("BUSCA", busca, busca.atendentes.filter(u => u.id == usuario.id))
        console.log("USUARIO", usuario)
        if (busca && busca.atendentes.filter(u => u.usuario_id == usuario.id).length > 0) {
            setAtendimentoAberto(busca)
            //setMensagens(busca.mensagens)
            setNavSelected("atendendo")
        } else {
            setAlertShow({
                type: "warning",
                title: "Atenção!",
                text: "Deseja iniciar o atendimento?",
                buttons: [
                    { type: "cancel", text: "Não" },
                    {
                        type: "confirm", text: "Sim", onPress: () => {
                            atender(atendimento.id)
                            setNavSelected("atendendo")
                        }
                    },
                ]
            })
        }
    }

    const atender = async (atendimento_id) => {
        let atendimento = await atenderAtendimento(atendimento_id)
        console.log("handleClickAtendimentoResumo", atendimento)
        setAtendimentoAberto(atendimento)
    }

    return (
        <>
            <Nav>
                <NavItem key="fila" onPress={() => setNavSelected("fila")}>
                    <NavItemText style={navSelected == "fila" && styles.selecionado}>
                        Fila
                        {atendimentos.filter(a => a.atendentes.filter(m => m.atendido && m.status == 1 && usuario && m.usuario_id == usuario.id).length == 0).length > 0 &&
                            <ContadorAtendimentos>{atendimentos.filter(a => a.atendentes.filter(m => m.atendido && m.status == 1).length == 0).length}</ContadorAtendimentos>
                        }
                    </NavItemText>
                </NavItem>
                <NavItem key="atendendo" onPress={() => setNavSelected("atendendo")}>
                    <NavItemText style={navSelected == "atendendo" && styles.selecionado}>
                        Atendendo
                        {atendimentos.filter(a => a.atendentes.filter(m => m.atendido && m.status == 1 && usuario && m.usuario_id == usuario.id).length > 0).length > 0 &&
                            <ContadorAtendimentos>{atendimentos.filter(a => a.atendentes.filter(m => m.atendido && m.status == 1 && m.usuario_id == usuario.id).length > 0).length}</ContadorAtendimentos>
                        }
                    </NavItemText>
                </NavItem>
            </Nav>

            <View style={{ flex: 1 }}>
                {atendimentosFiltrados.map(atendimento => (
                    <TouchableOpacity key={atendimento.id} onPress={() => handleClickAtendimentoResumo(atendimento)} key={atendimento.id} style={atendimentoAberto?.id == atendimento.id ? { background: "#F5F6F8" } : {}}>
                        <ResumoConversa>
                            <AvatarCanal size={60} canal={atendimento.canal_tipo_id} />
                            <View style={{ flexDirection: "column", justifyContent: "space-between", height: "100%", marginLeft: 15, paddingVertical: 5, flex: 1 }}>
                                <Text style={{ fontSize: 16, color: "#545A60" }}>{atendimento?.campos_iniciais?.filter(ci => ci.campo_contato == "nome").length > 0 ? atendimento?.campos_iniciais?.filter(ci => ci.campo_contato == "nome")[0].valor : "Cliente"}</Text>
                                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                    <Text style={{ fontSize: 13, color: "#545A60", maxWidth: "70%" }} numberOfLines={1} ellipsizeMode='tail'>
                                        {
                                            atendimento.atendentes.filter(a => a.atendido && a.status).length == 0
                                                ? "Aguardando"
                                                : atendimento.digitando
                                                    ? "Digitando..."
                                                    : (
                                                        ["imagem", "texto_imagem"].includes(atendimento.mensagens[atendimento.mensagens.length - 1].tipo)
                                                            ? (atendimento.mensagens[atendimento.mensagens.length - 1].imagens.length == 1 ? "Enviou uma imagem" : `Enviou ${atendimento.mensagens[atendimento.mensagens.length - 1].imagens.length} imagens`)
                                                            : ["arquivo", "texto_arquivo"].includes(atendimento.mensagens[atendimento.mensagens.length - 1].tipo)
                                                                ? (atendimento.mensagens[atendimento.mensagens.length - 1].arquivos.length == 1 ? "Enviou um arquivo" : `Enviou ${atendimento.mensagens[atendimento.mensagens.length - 1].arquivos.length} arquivos`)
                                                                : atendimento.mensagens[atendimento.mensagens.length - 1].mensagem
                                                    )
                                        }
                                    </Text>
                                    <Text style={{ fontSize: 12, color: "#545A60" }}>{formatDiferencaDatas(atendimento.mensagem_cadastro, null)}</Text>
                                </View>
                            </View>
                        </ResumoConversa>
                    </TouchableOpacity>
                ))}

                {atendimentosFiltrados.length == 0 && <NavItemText style={{ textAlign: "center", paddingVertical: 20 }}>Nenhum atendimento</NavItemText>}
            </View>
        </>
    )
}