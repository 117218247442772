import React from "react"
import { Text } from "react-native"

import { Button as Btn } from "./styles"

export default function Button({ children, color = "secondary", type, ...rest }) {
    const colors = {
        secondary: { background: "#8c98bb", color: "#ffffff" },
        warning: { background: "#ffc107", color: "#000000" },
        info: { background: "##0dcaf0", color: "#000000" },
        danger: { background: "#dc3545", color: "#ffffff" },
        success: { background: "#198754", color: "#ffffff" },
        light: { background: "#f8f9fa", color: "#000000" },
        dark: { background: "#212529", color: "#ffffff" },
        dark: { background: "#212529", color: "#ffffff" },
    }

    return (<>
        <Btn
            {...rest}
            style={{ ...{ background: colors[color].background }, ...rest.style }}
        >
            <Text style={{ color: colors[color].color }}>{children}</Text>
        </Btn>
    </>
    )
}