import React, { useContext } from "react"

import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Login from './pages/Login';

import AuthContext from "./contexts/AuthContext"
import Main from "./pages/Main";

const Stack = createNativeStackNavigator();

export default function Navigation() {
    const { logged } = useContext(AuthContext)

    return (
        <NavigationContainer>
            {logged ?
                <Stack.Navigator initialRouteName="Main" screenOptions={{ headerShown: false }}>
                    <Stack.Screen name="Main" component={Main} />
                </Stack.Navigator> :
                <Stack.Navigator initialRouteName="Login" screenOptions={{ headerShown: false }}>
                    <Stack.Screen name="Login" component={Login} />
                </Stack.Navigator>
            }
        </NavigationContainer>
    )
}