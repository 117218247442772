import React, { useContext, useState, useEffect } from "react"
import Mensagem from "./Mensagem"

import { formatHtmlToString } from "../../../../../services/formatService"

import { Container } from "./styles"

import ChatContext from "../../../../../contexts/ChatContext"
import AtendimentoContext from "../../../../../contexts/AtendimentoContext"

export default function Mensagens() {
    const { mensagens, pesquisa } = useContext(ChatContext)
    const { atendimentoAberto, atendimentos } = useContext(AtendimentoContext)

    return (
        <Container>
            {mensagens?.filter(m => (m.mensagem ? formatHtmlToString(m.mensagem.toLowerCase()) : "").indexOf(pesquisa.toLowerCase()) >= 0).map((mensagem, index) => (
                <Mensagem mensagem={mensagem} key={index} />
            ))}
            {atendimentos.filter(a => a.id == atendimentoAberto?.id)[0].digitando && <Mensagem mensagem={{ mensagem: "Digitando...", tipo: "digitando" }} key="outro-digitando" />}
        </Container>
    )
}