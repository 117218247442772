const Colors = {
    primary: '#00B5F7',
    cancel: '#EC6374',
    error: '#FF0000',
    attention: '#BC3E53',
    success: '#26D275',
    disabled: '#E9E9E9'
}
export default Colors

export const hexToRgba = (h, opacity = 1) => {
    let r = 0, g = 0, b = 0;

    // 3 digits
    if (h.length == 4) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];

        // 6 digits
    } else if (h.length == 7) {
        r = "0x" + h[1] + h[2];
        g = "0x" + h[3] + h[4];
        b = "0x" + h[5] + h[6];
    }

    return `rgba(${+r}, ${+g}, ${+b}, ${opacity})`;
}