import React from "react"

import AvatarDefault from "../../assets/images/avatar-default.jpeg"

import IconeWebchat from "../../assets/icones/canais/icone-webchat.png"
import IconeWhatsapp from "../../assets/icones/canais/icone-whatsapp.png"

import { Container, Image, Icone } from "./styles"

export default function AvatarCanal({ src, size, canal, style = {} }) {
    return (
        <Container>
            <Image source={src ? { uri: src } : AvatarDefault} style={{ width: size, height: size, ...style }} />

            {canal && ["webchat", "whatsapp"].includes(canal) && <Icone source={canal == "webchat" ? IconeWebchat : canal == "whatsapp" ? IconeWhatsapp : null} />}
        </Container>
    )
}