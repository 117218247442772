import React, { useContext, useMemo } from "react"
import { View, Text, TouchableOpacity } from "react-native"

import AtendimentoContext from "../../../../contexts/AtendimentoContext"

import Icon from 'react-native-vector-icons/FontAwesome5';

import Avatar from "../../../../components/Avatar"

import { Wrapper, CardTitle, Card, Icone, BotaoAdicionar } from "./styles"

import { formatDataHora } from "../../../../services/formatService"

import IconeWebchat from "../../../../assets/icones/canais/icone-webchat.png"
import IconeWhatsapp from "../../../../assets/icones/canais/icone-whatsapp.png"
import AppContext from "../../../../contexts/AppContext";
import DirecionarContato from "../../../../components/DirecionarContato";
import Contato from "../../../../components/Contato";

export default function SessaoColuna() {
    const { setModalShow, setAlertShow } = useContext(AppContext)
    const { atendimentoAberto } = useContext(AtendimentoContext)

    const handleClickAdicionar = () => {
        console.log("handleClickAdicionar")

        setModalShow({
            size: "md",
            content: <DirecionarContato dados={atendimentoAberto} />,
            title: "Direcionar Contato"
        })
    }

    const abrirContato = (contato) => {
        setModalShow({
            size: "md",
            content: <Contato contato={contato} />,
            title: contato.nome || "Contato"
        })
    }

    const CardContatoWrapper = ({ children }) => {
        console.log("CardContatoWrapper", atendimentoAberto)
        if (atendimentoAberto.contato)
            return <TouchableOpacity onPress={() => abrirContato(atendimentoAberto.contato)}>{children}</TouchableOpacity>
        return <>{children}</>
    }

    return (
        <Wrapper>
            <CardContatoWrapper>
                <Card style={{ marginVertical: 20, padding: 20, textAlign: "center", flexDirection: "column", alignItems: "center" }}>
                    <Avatar src={null} size={100} />
                    <Text style={{ fontSize: 18, color: "#545A60", marginTop: 10 }}>
                        {atendimentoAberto?.contato ? atendimentoAberto.contato.nome : atendimentoAberto?.campos_iniciais?.filter(ci => ci.campo_contato == "nome").length > 0 ? atendimentoAberto?.campos_iniciais?.filter(ci => ci.campo_contato == "nome")[0].valor : "Cliente"}
                    </Text>

                    {!atendimentoAberto?.contato && <BotaoAdicionar onPress={handleClickAdicionar}>
                        <Icon name="user" size={14} color="#ffffff" />
                    </BotaoAdicionar>}
                </Card>
            </CardContatoWrapper>

            <CardTitle>Sessão</CardTitle>
            <Card style={{ marginBottom: 20 }}>
                <View style={{ paddingVertical: 10 }}>
                    <Text style={{ fontSize: 12, color: "#545A60" }}>Canal</Text>
                    <View style={{ flexDirection: "row", alignItems: "center", marginTop: 5 }}>
                        <Icone source={atendimentoAberto.canal_tipo_id == "webchat" ? IconeWebchat : atendimentoAberto.canal_tipo_id == "whatsapp" ? IconeWhatsapp : null} />
                        <View style={{ marginLeft: 10 }}>
                            <Text style={{ fontSize: 12, color: "#545A60" }}>{atendimentoAberto.canal_tipo_id == "webchat" ? "WebChat" : atendimentoAberto.canal_tipo_id == "whatsapp" ? "WhatsApp" : ""}</Text>
                            <Text style={{ fontSize: 14, color: "#545A60" }}>{atendimentoAberto.canal_nome}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ paddingVertical: 10, borderTopWidth: 1, borderTopColor: "#D3D4D9" }}>
                    <Text style={{ fontSize: 12, color: "#545A60" }}>Início</Text>
                    <Text style={{ fontSize: 14, color: "#545A60" }}>{formatDataHora(atendimentoAberto.datahora_cadastro, "DD/MM/YYYY - HH:mm:ss")}</Text>
                </View>
                <View style={{ paddingVertical: 10, borderTopWidth: 1, borderTopColor: "#D3D4D9" }}>
                    <Text style={{ fontSize: 12, color: "#545A60" }}>Setor</Text>
                    <Text style={{ fontSize: 14, color: "#545A60" }}>{atendimentoAberto.setor_nome}</Text>
                </View>
            </Card>

            <CardTitle>Variáveis de entrada</CardTitle>
            <Card>
                {atendimentoAberto?.campos_iniciais.length == 0 && <Text>Nenhuma</Text>}
                {(atendimentoAberto?.contato ? atendimentoAberto.contato.campos_adicionais : atendimentoAberto?.campos_iniciais).map((campo, index) => (
                    <View key={index} style={{ paddingVertical: 10, borderTopWidth: index ? 1 : 0, borderTopColor: "#D3D4D9" }}>
                        <Text style={{ fontSize: 12, color: "#545A60" }}>{campo.nome}</Text>
                        <Text style={{ fontSize: 14, color: "#545A60" }}>{campo.valor || "-"}</Text>
                    </View>
                ))}
            </Card>
        </Wrapper>
    )
}