import React, { useContext } from "react"
import { View, TouchableOpacity } from "react-native"
import Icon from 'react-native-vector-icons/FontAwesome5';
import AppContext from "../../contexts/AppContext";

import { Container, Imagem } from "./styles"

export default function ImageViewer() {
    const { imageViewerShow, setImageViewerShow } = useContext(AppContext)

    return (
        <>
            {imageViewerShow && <Container>
                <View style={{ flexDirection: "column", alignItems: "flex-end", justifyContent: "center" }}>
                    <TouchableOpacity>
                        <Icon name="times" size={24} color="#ffffff" onPress={() => setImageViewerShow(null)} />
                    </TouchableOpacity>
                    <Imagem
                        style={{ flex: 1 }}
                        source={{ uri: imageViewerShow }}
                        resizeMode="contain"
                    />
                </View>
            </Container>}
        </>
    )
}