import styled from "styled-components/native"

export const Container = styled.ScrollView`
    position: absolute;
    bottom: 70px;
    width: 100%;
    background: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: row;
`

export const Arquivo = styled.View`
    width: 300px;
    height: 80px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    margin-left: 10px;
`

export const Icone = styled.View`
    height: 60px;
    width: 60px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    background-color: #eeeeee;
    margin-right: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
`