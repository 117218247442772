import React from "react"
import { View } from "react-native"
import LoginForm from "../../components/LoginForm"

import styles from "./styles"

export default function Login() {
    return (
        <View style={styles.container}>
            <LoginForm />
        </View>
    )
}