import React, { useContext } from "react"
import { View } from "react-native"
import AtendimentoContext from "../../../contexts/AtendimentoContext"
import ChatContext from "../../../contexts/ChatContext"
import Chat from "./Chat"
import OpcoesColuna from "./OpcoesColuna"
import SessaoColuna from "./SessaoColuna"

import { Wrapper } from "./styles"

export default function Atendimento() {
    const { atendimentoAberto } = useContext(AtendimentoContext)
    const { opcoesColunaShow, sessaoColunaShow } = useContext(ChatContext)

    return (
        <Wrapper>
            {(!atendimentoAberto || opcoesColunaShow) && <OpcoesColuna />}
            {atendimentoAberto && <Chat />}
            {atendimentoAberto && sessaoColunaShow && <SessaoColuna />}
        </Wrapper>
    )
}