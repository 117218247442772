import styled from "styled-components/native"

export const ContatoCard = styled.TouchableOpacity`
    padding: 10px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #eee;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
`