import React, { useRef, useEffect, useState, useContext } from "react"
import { View } from "react-native"

import { Form } from '@unform/mobile'

import Input from "../forms/Input"
import Button from "../Button"

import { getCamposAdicionais } from "../../services/campoAdicionalService"
import { postContatos } from "../../services/contatosService"

import Loading from "../Loading"
import AppContext from "../../contexts/AppContext"
import AtendimentoContext from "../../contexts/AtendimentoContext"

export default function CadastrarContato({ voltar, dados = null }) {
    const formRef = useRef()

    const { setModalShow } = useContext(AppContext)
    const { setAtendimentos } = useContext(AtendimentoContext)

    const [loading, setLoading] = useState(true)
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    const [camposAdicionais, setCamposAdicionais] = useState([])

    useEffect(async () => {
        try {
            let resultado = await getCamposAdicionais()
            setCamposAdicionais(resultado)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }, [])

    const handleSubmit = async (data) => {
        try {
            setLoadingSubmit(true)

            console.log("handleSubmit", data)

            let campos_adicionais = []
            for (let key of Object.keys(data)) {
                if (key.indexOf("campo_adicional_") == 0) {
                    campos_adicionais.push({
                        id: +key.replace("campo_adicional_", ""),
                        valor: data[key]
                    })
                }
            }

            let json = {
                nome: data.nome,
                campos_adicionais
            }

            if (dados?.sessao_id)
                json.sessao_id = dados.sessao_id

            let contato = await postContatos(json)

            setAtendimentos((atendimentos) => {
                for (let atendimento of atendimentos) {
                    if (atendimento.id == dados.id) {
                        atendimento.contato = contato
                        break
                    }
                }
                return atendimentos
            })

            setModalShow(null)

            setLoadingSubmit(false)
        } catch (error) {
            setLoadingSubmit(false)
        }
    }

    return (
        <>
            {loading && <Loading />}

            {!loading && <Form ref={formRef} onSubmit={handleSubmit}>
                <Input name="nome" label="Nome" placeholder="Nome" defaultValue={(() => {
                    let busca = dados?.campos_iniciais.filter(ci => ci.campo_contato == "nome")
                    if (!busca || busca.length == 0) return ""
                    return busca[0].valor
                })()} />

                {camposAdicionais.map(campo => <Input key={campo.id} name={`campo_adicional_${campo.id}`} label={campo.nome} placeholder={campo.nome} defaultValue={(() => {
                    let busca = dados?.campos_iniciais.filter(ci => ci.campo_adicional_id == campo.id)
                    if (!busca || busca.length == 0) return ""
                    return busca[0].valor
                })()} />)}
            </Form>}

            <View style={{ marginTop: 20, textAlign: "right", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                {voltar && <Button color="warning" onPress={voltar} style={{ marginRight: 10 }} disable={loadingSubmit}>Voltar</Button>}
                <Button color="success" onPress={() => formRef.current.submitForm()} disable={loadingSubmit}>Cadastrar</Button>
            </View>
        </>
    )
}