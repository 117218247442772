import React, { useState } from "react"
import { View } from "react-native"

import Button from "../Button"
import CadastrarContato from "../CadastrarContato"
import PesquisaContato from "./PesquisaContato"

export default function DirecionarContato({ dados }) {
    const [selecao, setSelecao] = useState("pesquisa")

    const voltar = () => {
        setSelecao("pesquisa")
    }

    return (
        <>
            {selecao == "pesquisa" && <>
                <PesquisaContato dados={dados} />

                <View style={{ borderTopWidth: 1, borderTopColor: "#EEEEEE", paddingTop: 20 }}>
                    <Button onPress={() => setSelecao("novo-contato")}>Novo contato</Button>
                </View>
            </>}
            {selecao == "novo-contato" && <>
                <CadastrarContato dados={dados} voltar={voltar} />
            </>}
        </>
    )
}