import React from "react"

import AvatarDefault from "../../assets/images/avatar-default.jpeg"

import { Image } from "./styles"

export default function Avatar({ src, size, style = {} }) {
    return (
        <Image source={src ? { uri: src } : AvatarDefault} style={{ width: size, height: size, ...style }} />
    )
}