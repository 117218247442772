import styled from "styled-components/native"

export const Container = styled.ScrollView`
    position: absolute;
    bottom: 70px;
    width: 100%;
    max-height: 400px;
    background: #ffffff;
`

export const Atalho = styled.TouchableOpacity`
    padding: 10px;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #eeeeee;
`