import React, { useContext, useState, useEffect } from "react"
import { View, Dimensions } from "react-native"

import socket from "../../../../../services/socketService"

import { v4 as uuidv4 } from 'uuid';
import moment from "moment"

import Icon from 'react-native-vector-icons/FontAwesome5';

import { Container, Input, Button } from "./styles"

import ChatContext from "../../../../../contexts/ChatContext";
import AtendimentoContext from "../../../../../contexts/AtendimentoContext";

import { enviarMensagem } from "../../../../../services/chatService"

// Atalhos
import * as DocumentPicker from 'expo-document-picker';
import Atalhos from "./Atalhos";
import Arquivos from "./Arquivos";

export default function Editor() {
    const { mensagem, setMensagem, setMensagens } = useContext(ChatContext)
    const { atendimentoAberto } = useContext(AtendimentoContext)

    const { shiftPressed, setShiftPressed } = useState(false)
    const [digitando, setDigitando] = useState(false)

    const [showAtalhos, setShowAtalhos] = useState(false)

    const [arquivos, setArquivos] = useState([])
    const [imagens, setImagens] = useState([])

    const [showBotoesEsquerda, setShowBotoesEsquerda] = useState(Dimensions.get('window').width > 600)
    const [mobile, setMobile] = useState(Dimensions.get('window').width <= 600)

    useEffect(() => {
        let msg = mensagem.trim()

        if (msg == "") {
            setDigitando(false)
        } else {
            setDigitando(true)
        }
    }, [mensagem])

    useEffect(() => {
        console.log("DIGITANDO", digitando)
        socket.emit("digitando_server", {
            room: `chat:${atendimentoAberto.sessao_uuid}`,
            digitando: digitando
        })
    }, [digitando])

    const selecionarArquivos = async (data = {}) => {
        try {
            let options = { multiple: true }
            options = { ...options, ...data }

            let result = await DocumentPicker.getDocumentAsync(options);

            let arquivo = result
            console.log("ARQUIVO", arquivo)

            if (data.imagem) setImagens([...imagens, arquivo])
            else setArquivos([...arquivos, arquivo])
        } catch (err) {
            console.error("ERRO DOCUMENT PICKER", err)
        }
    }

    const handleSubmit = async () => {
        console.log("handleSubmit", mensagem)
        try {
            if (mensagem.trim().length == 0 && arquivos.length == 0 && imagens.length == 0)
                return

            let tipo = "texto"
            if (arquivos.length > 0) {
                tipo = "arquivo"
                if (mensagem.trim().length > 0)
                    tipo = "texto_arquivo"
            } else if (imagens.length > 0) {
                tipo = "imagem"
                if (mensagem.trim().length > 0)
                    tipo = "texto_imagem"
            }

            let id_externo = uuidv4()

            let objMensagem = {
                id_externo: id_externo,
                mensagem: mensagem.trim(),
                datahora: moment(),
                tipo: tipo,
                status: 0,
                sessao_id: atendimentoAberto.sessao_id
            }

            let files = []
            for (let arquivo of arquivos) {
                let { uri: base64, name: nome } = arquivo
                let { type: tipo } = arquivo.file
                files.push({ base64, nome, tipo })
            }

            if (files.length > 0)
                objMensagem.arquivos = files

            let images = []
            for (let imagem of imagens) {
                let { uri: base64, name: nome } = imagem
                let { type: tipo } = imagem.file
                images.push({ base64, nome, tipo })
            }

            if (images.length > 0)
                objMensagem.imagens = images

            setMensagem("")
            setArquivos([])
            setImagens([])

            try {
                console.log("NOVA MENSAGEM QUE SERÁ CADASTRADA", objMensagem)
                setMensagens((mensagens) => {
                    return [...mensagens, objMensagem]
                })

                await enviarMensagem(objMensagem)
            } catch (error) {
                console.error(error)
                setMensagens((mensagens) => {
                    for (let i = 0; i < mensagens.length; i++) {
                        let msg = mensagens[i]
                        if (msg.id_externo == msg.id_externo) {
                            mensagens[i].status = 4
                            break
                        }
                    }
                    return mensagens
                })
            }
        } catch (error) {
            console.error(error)
            alert("erro")
        }
    }

    return (
        <>
            {[...imagens, ...arquivos].length > 0 && <Arquivos arquivos={arquivos} setArquivos={setArquivos} imagens={imagens} setImagens={setImagens} />}
            {showAtalhos && <Atalhos mensagem={mensagem} setMensagem={setMensagem} setShowAtalhos={setShowAtalhos} />}
            <Container>
                {showBotoesEsquerda && <View style={mobile ? { position: "absolute", bottom: 80, left: 20, backgroundColor: "#ffffff", borderRadius: 5 } : {}}>
                    <Button><Icon name='smile' size={32} color="#8C98BB" /></Button>
                    <Button onPress={() => {
                        if (mobile) setShowBotoesEsquerda(false)
                        selecionarArquivos()
                    }} disabled={imagens.length}>
                        <Icon name='paperclip' size={32} color={`rgba(140, 152, 187, ${imagens.length ? 0.6 : 1})`} />
                    </Button>
                    <Button onPress={() => {
                        if (mobile) setShowBotoesEsquerda(false)
                        selecionarArquivos({ imagem: true, type: "image/png,image/jpeg,image/gif" })
                    }} disabled={arquivos.length}>
                        <Icon name='file-image' size={32} color={`rgba(140, 152, 187, ${arquivos.length ? 0.6 : 1})`} />
                    </Button>
                </View>}
                {mobile && <Button onPress={() => setShowBotoesEsquerda(!showBotoesEsquerda)}><Icon name='ellipsis-v' size={32} color="#8C98BB" style={{ marginRight: 10 }} /></Button>}
                <Input value={mensagem} onChange={(m) => {
                    let msg = m.target.value
                    setShowAtalhos(msg.trim().length > 0 && msg.trim()[0] == "/" && msg.split(" ").length == 1)
                    setMensagem(msg)
                }} placeholder="Escreva aqui sua mensagem..." onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === 'Enter')
                        handleSubmit()
                }} multiline={true} />
                <Button onPress={handleSubmit}><Icon name='paper-plane' size={32} color="#8C98BB" /></Button>
            </Container>
        </>
    )
}