import React, { useState, createContext, useEffect, useContext } from 'react'
import { Dimensions } from "react-native"

import moment from "moment"

import socket from "../services/socketService"

import AtendimentoContext from "../contexts/AtendimentoContext"

const ChatContext = createContext({})

export const ChatProvider = ({ children }) => {
    const { atendimentoAberto, setAtendimentoAberto, atendimentos, setAtendimentos, excluirAtendimento } = useContext(AtendimentoContext)

    const [loadingChat, setLoadingChat] = useState(true)

    const [sessionToken, setSessionToken] = useState(null)
    const [webChatConfiguracoes, setWebChatConfiguracoes] = useState(null)

    const [mensagem, setMensagem] = useState("") // Que está sendo digitada no editor
    const [editorSubmit, setEditorSubmit] = useState(false)

    const [mensagens, setMensagens] = useState([])

    const [showEditor, setShowEditor] = useState(true)

    const [pesquisa, setPesquisa] = useState("")

    const [opcoesColunaShow, setOpcoesColunaShow] = useState(Dimensions.get('window').width > 600 ? true : false)
    const [sessaoColunaShow, setSessaoColunaShow] = useState(Dimensions.get('window').width > 600 ? true : false)

    useEffect(() => {
        listener()
    }, [])

    useEffect(() => {
        console.log("ATENDIMENTO ABERTO ALTERADO", atendimentoAberto)
        setMensagens(() => {
            if (!atendimentoAberto) return atendimentoAberto
            console.log("MENSAGENS ABERTAS", atendimentoAberto.mensagens)
            return atendimentoAberto.mensagens
        })

        /*setAtendimentos(atendimentos => {
            if (atendimentos) {
                for (let atendimento of atendimentos) {
                    if (atendimento.id == atendimentoAberto.id) {
                        for (let mensagem of atendimento.mensagens) {
                            if (mensagem.status == 1 && !mensagem.recebido) {
                                socket.emit("mensagem_recebida", mensagem.id)
                            }
                        }

                        break
                    }
                }
            }
        })*/
    }, [atendimentoAberto])

    useEffect(() => {
        console.log("ATENDIMENTOS ALTERADOS", atendimentos)
        setAtendimentoAberto((atendimentoAberto) => {
            if (!atendimentoAberto) return atendimentoAberto

            let atend = atendimentos.filter(a => a.id == atendimentoAberto.id)

            console.log("ATEND", atend, atend[0].digitando)

            return atend.length ? atend[0] : atendimentoAberto
        })

        // Atualizar mensagens
        if (atendimentoAberto) {
            setMensagens((mensagens) => {
                let atend = atendimentos.filter(a => a.id == atendimentoAberto.id)

                return atend.length ? atend[0].mensagens : mensagens
            })
        }

        // Excluir atendimentos encerrados
        for (let atendimento of atendimentos) {
            if (atendimento.status == 0) {
                console.log("ATENDIMENTO PARA EXCLUSÃO ENCONTRADO", atendimento)
                if (atendimentoAberto && atendimento.id == atendimentoAberto.id)
                    setAtendimentoAberto(null)
                excluirAtendimento(atendimento, false)
            }
        }
    }, [atendimentos])

    const encerrarChat = () => {
        if (showEditor) {
            setShowEditor(false)
            setMensagens((msgs) => [...msgs, {
                tipo: "notificacao",
                mensagem: "Atendimento finalizado",
                envio: false,
                datahora: moment()
            }])
        }
    }

    const listener = () => {
        socket.on("mensagem", mensagem => {
            setAtendimentos((atendis) => {
                console.log("CHEGOU MENSAGEM NOVA", mensagem, atendis)
                let atends = [...atendis]
                for (let atend of atends) {
                    console.log("1111", atend.sessao_id, mensagem.sessao_id)
                    if (atend.sessao_id == mensagem.sessao_id) {
                        console.log("2222", mensagem.id, mensagem.id_externo)
                        console.log(atend.mensagens)
                        if (atend.mensagens.filter(m => (m.id && m.id == mensagem.id) || (m.id_externo && m.id_externo == mensagem.id_externo)).length > 0) {
                            for (let i = 0; i < atend.mensagens.length; i++) {
                                let msg = atend.mensagens[i]
                                console.log("MSG", msg)
                                if ((msg.id && msg.id == mensagem.id) || (mensagem.id_externo && msg.id_externo == mensagem.id_externo)) {
                                    console.log("STATUS ATUAL", msg.status)
                                    atend.mensagens[i] = mensagem
                                    console.log("MUDOOOOOOUUUUUUUUUUUUUU")
                                }
                            }
                        } else {
                            atend.mensagens.push(mensagem)
                        }
                    }
                }
                console.log("setAtendimentos 2", atends)

                return atends
            })

            /*if (mensagem?.sessao_id == atendimentoAberto?.sessao_id) {
                setMensagens((msgs) => {
                    let mens = [...msgs]
                    let achou = false
    
                    for (let i = 0; i < msgs.length; i++) {
                        let msg = mens[i]
                        if ((mensagem.id_externo && msg.id_externo == mensagem.id_externo) || msg.id == mensagem.id) {
                            mens[i] = mensagem
                            achou = true
                            break
                        }
                    }
    
                    if (!achou)
                        mens.push(mensagem)
    
                    return mens
                })
            }*/
        })

        socket.on("novo-atendimento", atendimento => {
            console.log("NOVO ATENDIMENTO", atendimento)

            setAtendimentos((atendimentos) => {
                if (atendimentos.filter(a => a.id == atendimento.id).length == 0) {
                    return [...atendimentos, atendimento]
                }
                return atendimentos
            })
        })

        socket.on("atendimento-atualizado", atendimento => {
            console.log("ATENDIMENTO ATUALIZADO", atendimento)

            if (atendimento.status == 0) {
                console.log("status == 0", atendimentoAberto)
                setAtendimentoAberto((atendimentoAberto) => {
                    if (atendimentoAberto && atendimentoAberto.id == atendimento.id)
                        return null
                    return atendimentoAberto
                })
                setAtendimentos((atendimentos) => atendimentos.filter(a => a.id != atendimento.id))
            } else {
                setAtendimentos((atendimentos) => {
                    for (let i = 0; i < atendimentos.length; i++) {
                        let atend = atendimentos[i]
                        if (atend.id == atendimento.id) {
                            atendimentos[i] = atendimento
                            break
                        }
                    }
                    console.log("VAI ATUALIZAR COM", atendimentos)
                    return atendimentos
                })
            }
        })

        socket.on("digitando_cliente", digitando => {
            console.log("CLIENTE DIGITANDO", digitando)
            setAtendimentos((atendimentos) => {
                if (digitando) {
                    let atends = [...atendimentos]
                    for (let atendimento of atends) {
                        console.log(`chat:${atendimento.sessao_uuid}`, digitando.room)
                        if (`chat:${atendimento.sessao_uuid}` == digitando.room) {
                            console.log("ACHEI")
                            atendimento.digitando = digitando.digitando
                            break
                        }
                    }

                    return atends
                }
            })
        })

        socket.on("chat_finalizado", () => {
            encerrarChat()
        })
    }

    return (
        <ChatContext.Provider value={{
            loadingChat, setLoadingChat,
            sessionToken, setSessionToken,
            webChatConfiguracoes, setWebChatConfiguracoes,
            editorSubmit, setEditorSubmit,
            mensagem, setMensagem,
            mensagens, setMensagens,
            pesquisa, setPesquisa,
            opcoesColunaShow, setOpcoesColunaShow, sessaoColunaShow, setSessaoColunaShow,
            showEditor
        }}>
            {children}
        </ChatContext.Provider>
    )
}

export default ChatContext
