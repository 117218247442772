import styled from "styled-components/native"

export const Container = styled.View`
    position: relative;
`

export const Image = styled.Image`
    border-radius: 100%;
`

export const Icone = styled.Image`
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -5px;
    right: -5px;
`