import { StyleSheet } from 'react-native'
import styled from "styled-components/native"

const styles = StyleSheet.create({
    selecionado: {
        fontWeight: "bold"
    }
})

export default styles

export const Wrapper = styled.View`
    flex: 1;
    height: 100%;
    background: #ffffff;
    shadow-opacity: 0.2;
    shadow-radius: 5px;
    shadow-color: #000000;
    shadow-offset: 5px 5px;
    elevation: 1;
    z-index: 2;
`

export const ResumoConversa = styled.View`
    padding: 10px 20px;
    border: 1px solid #F5F6F8;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const Nav = styled.View`
    padding: 20px 0;
    border: 1px solid #F5F6F8;
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const NavItem = styled.TouchableOpacity`
    flex: 1;
    text-align: center;
`

export const NavItemText = styled.Text`
    color: #8C98BB;
    font-size: 15px;
`

export const ContadorAtendimentos = styled.Text`
    color: #ffffff;
    font-size: 15px;
    background: #BED62D;
    padding: 0px 5px;
    border-radius: 5px;
    margin-left: 5px;
`

export const BottomMenu = styled.View`
    display: flex;
    flex-direction: row;
    border-top-width: 1px;
    border-top-color: #F5F6F8;
`

export const BottomMenuLink = styled.TouchableOpacity`
    flex: 1;
    padding: 20px;
    text-align: center;
`