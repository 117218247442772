import React, { useContext } from "react"
import { View, Text, TouchableOpacity } from "react-native"

import RenderHTML from "react-native-render-html";
import AuthContext from "../../../../../../contexts/AuthContext";

import Icon from 'react-native-vector-icons/FontAwesome5';

import styles, { Container, Imagem, Arquivos, Arquivo, Icone } from "./styles"
import AppContext from "../../../../../../contexts/AppContext";

export default function Mensagem({ mensagem }) {
    const { usuario } = useContext(AuthContext)
    const { setImageViewerShow } = useContext(AppContext)

    const abrirImagem = (imagem) => {
        console.log("abrirImagem", imagem)
        setImageViewerShow(imagem.link || imagem.base64)
    }

    return (
        <>
            {
                mensagem.tipo == "notificacao" ? <>
                    <Text style={{ textAlign: "center", fontSize: 14, paddingVertical: 10 }}>{mensagem.mensagem}</Text>
                </> : mensagem.tipo == "digitando" ?
                    <Text style={{ textAlign: "left", fontSize: 14, paddingVertical: 10 }}>{mensagem.mensagem}</Text>
                    :
                    (
                        <Container
                            style={!mensagem.envio ? {
                                background: mensagem.usuario_id == null && mensagem.hasOwnProperty("id") ? "#FFFFFF" : mensagem.usuario_id == usuario.id || !mensagem.hasOwnProperty("id") ? "#D5EDA1" : "#FBF5BB",
                                alignSelf: "flex-end"
                            } : { background: "#CEE5FB", alignSelf: "flex-start" }}>
                            {["texto"].includes(mensagem.tipo) &&
                                <RenderHTML
                                    contentWidth={100}
                                    source={{ html: mensagem.mensagem }}
                                    style={{
                                        fontSize: 14,
                                        color: "#545A60",
                                        wordWrap: "break-word"
                                    }}
                                />
                            }

                            {["imagem", "imagem-texto"].includes(mensagem.tipo) && <>
                                {mensagem.imagens.map((imagem, index) => (
                                    <TouchableOpacity onPress={() => { abrirImagem(imagem) }} key={index}>
                                        <Imagem source={{ uri: imagem.link || imagem.base64 }} />
                                    </TouchableOpacity>
                                ))}
                            </>}

                            {["arquivo", "arquivo-texto"].includes(mensagem.tipo) && <>
                                <Arquivos>
                                    {mensagem.arquivos.map((arquivo, index) => (
                                        <Arquivo key={index}>
                                            <Icone style={{ backgroundImage: arquivo.formato.split("/")[0] == "image" ? arquivo.uri : null }}>
                                                {arquivo.formato.split("/")[0] != "image" ? <Icon name="paperclip" size={32} color="#8C98BB" /> : <></>}
                                            </Icone>
                                            <View style={{ flexDirection: "column", justifyContent: "flex-start", height: "100%", flex: 1 }}>
                                                <Text style={{ fontSize: 14, fontWeight: 500, marginBotton: 10 }}>{arquivo.nome}</Text>
                                            </View>
                                        </Arquivo>
                                    ))}
                                </Arquivos>
                            </>}

                            {!mensagem.envio && <Icon name={mensagem.status == 0 ? "clock" : mensagem.status == 1 ? "check" : "check-double"} size={12} color="#8C98BB" style={{ textAlign: "right", marginLeft: 5 }} />}
                        </Container>
                    )
            }
        </>
    )
}