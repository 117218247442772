import React, { useContext } from "react"
import { View, Text } from "react-native"

import AuthContext from "../../../contexts/AuthContext"

import { Container, Circulo, Situacao } from "./styles"

export default function EstadoUsuarioDropdown() {
    const { usuario, situacao, setSituacao } = useContext(AuthContext)

    const values = [
        { situacao: "online", color: "green", nome: "Online" },
        { situacao: "offline", color: "red", nome: "Offline" },
        { situacao: "ausente", color: "yellow", nome: "Ausente" }
    ]

    const handleClickSituacao = (situacao) => {
        setSituacao(situacao)
    }

    return (
        <Container>
            {situacao && values.filter(v => v.situacao != situacao).map((s, index) => (
                <Situacao onPress={() => handleClickSituacao(s.situacao)} key={index}>
                    <Circulo style={{ background: s.color }} />
                    <Text>{s.nome}</Text>
                </Situacao>
            ))}
        </Container>
    )
}