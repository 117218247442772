import React, { useEffect } from "react"
import { Text, TouchableOpacity, View } from "react-native"
import Icon from 'react-native-vector-icons/FontAwesome5';

import { Container, Arquivo, Icone } from "./styles"

export default function Arquivos({ arquivos, setArquivos, imagens, setImagens }) {
    useEffect(() => {
        console.log("ARQUIVOS ALTERADOS", arquivos)
    }, [arquivos])

    const bytesToSize = (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    const removerArquivo = (index) => {
        if (arquivos.length) {
            setArquivos(arquivos => {
                arquivos = arquivos.filter((a, i) => i != index)
                return arquivos
            })
        }

        if (imagens.length) {
            setImagens(imagens => {
                imagens = imagens.filter((a, i) => i != index)
                return imagens
            })
        }
    }

    return (
        <Container style={{ display: "flex", flexDirection: "row" }}>
            {[...arquivos, ...imagens]?.map((arquivo, index) => (
                <Arquivo key={index}>
                    <Icone style={{ backgroundImage: arquivo.file.type.split("/")[0] == "image" ? arquivo.uri : null }}>
                        {arquivo.file.type.split("/")[0] != "image" ? <Icon name="paperclip" size={32} color="#8C98BB" /> : <></>}
                    </Icone>
                    <View style={{ flexDirection: "column", justifyContent: "flex-start", height: "100%", flex: 1 }}>
                        <Text style={{ fontSize: 14, fontWeight: 500, marginBotton: 10 }}>{arquivo.name}</Text>
                        <Text style={{ fontSize: 12 }}>{bytesToSize(arquivo.size)}</Text>
                    </View>
                    <View style={{ height: "100%", flexDirection: "row", justifyContent: "flex-start" }}>
                        <TouchableOpacity onPress={() => removerArquivo(index)}>
                            <Icon name="times" size={18} color="#8C98BB" />
                        </TouchableOpacity>
                    </View>
                </Arquivo>
            ))}
        </Container>
    )
}