import { StyleSheet } from 'react-native'
import styled from "styled-components/native"

const styles = StyleSheet.create({
    enviado: {
        background: "red"
    },
    recebido: {
        background: "green"
    }
})

export default styles

export const Container = styled.View`
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background: #ffffff;
    shadow-opacity: 0.2;
    shadow-radius: 5px;
    shadow-color: #000000;
    shadow-offset: -5px 5px;
    elevation: 8;
    z-index: 3;
`

export const Button = styled.TouchableOpacity`
    height: 40px;
    background: #8C98BB;
    border-radius: 5px;
    padding: 5px 20px;
    color: #ffffff;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
`

export const InputSearch = styled.TextInput`
    height: 40px;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    padding: 5px 20px;
    color: #8C98BB;
    margin-left: 20px;
`