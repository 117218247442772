import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    container: {
        minHeight: "100vh",
        fontFamily: "sans-serif",
        color: "#555b6d"
    }
});

export default styles