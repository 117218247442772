import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    container: {
        backgroundColor: "#ffffff",
        padding: 30,
        borderRadius: 3,
        minWidth: "30vw",
        maxWidth: "95vw"
    },
    title: {
        fontSize: "1.125rem",
        marginBottom: 10,
        textAlign: "center",
    },
    subtitle: {
        fontSize: "1rem",
        color: "#aaaaaa",
        marginBottom: 20,
        textAlign: "center",
    },
    button: {
        marginTop: 20
    },
    errorMessage: {
        color: "#ff0000",
        marginTop: 20,
        textAlign: "center"
    }
});

export default styles