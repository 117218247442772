import React, { useContext, useEffect } from "react"
import { View, TouchableOpacity, Text } from "react-native"
import Icon from 'react-native-vector-icons/FontAwesome5';
import AppContext from "../../contexts/AppContext";

import styles, { Container, ModalWrapper, ModalHeader, ModalBody } from "./styles"

export default function Modal() {
    const { modalShow, setModalShow } = useContext(AppContext)

    useEffect(() => {
        console.log("MODAL SHOW", modalShow)
    }, [modalShow])

    return (
        <>
            {modalShow &&
                <Container>
                    <View style={{ flexDirection: "column", alignItems: "flex-end", justifyContent: "center" }}>
                        <ModalWrapper style={styles[modalShow.size] || styles.md}>
                            <ModalHeader style={{ borderBottomWidth: 1, borderBottomColor: "#eeeeee" }}>
                                {modalShow.title && <Text style={{ fontSize: 16, color: "#8C98BB" }}>{modalShow.title}</Text>}
                                <TouchableOpacity>
                                    <Icon name="times" size={18} color="#8C98BB" onPress={() => setModalShow(null)} />
                                </TouchableOpacity>
                            </ModalHeader>
                            <ModalBody>
                                {modalShow.content}
                            </ModalBody>
                        </ModalWrapper>
                    </View>
                </Container>
            }
        </>
    )
}