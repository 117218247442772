import styled from "styled-components/native"

export const Button = styled.TouchableOpacity`
    height: 40px;
    border-radius: 5px;
    padding: 5px 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100%;
`

export const MenuLink = styled.View`
    font-size: 15px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    height: 100%;
    position: relative;
`