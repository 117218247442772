import React, { useContext } from "react"
import ChatContext from "../../../../contexts/ChatContext"
import ChatHeader from "./ChatHeader"
import Editor from "./Editor"
import Mensagens from "./Mensagens"

import { Wrapper } from "./styles"

export default function Chat() {
    const { opcoesColunaShow, sessaoColunaShow } = useContext(ChatContext)

    return (
        <Wrapper style={{ flex: !opcoesColunaShow || !sessaoColunaShow ? 4 : 3 }}>
            <ChatHeader />
            <Mensagens />
            <Editor />
        </Wrapper>
    )
}