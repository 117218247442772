import React, { useState, createContext, useEffect, useContext } from 'react'

import socket from "../services/socketService"

import { getAtendimentos, encerrarAtendimento } from "../services/atendimentoService"
import AuthContext from './AuthContext'

const AtendimentoContext = createContext({})

export const AtendimentoProvider = ({ children }) => {
    const { logged } = useContext(AuthContext)

    const [atendimentos, setAtendimentos] = useState([])
    const [atendimentoAberto, setAtendimentoAberto] = useState(null)
    const [loadingAtendimentos, setLoadingAtendimentos] = useState(false)

    useEffect(() => {
        console.log("useEffect logged", logged)
        //if (logged) {
        buscarAtendimentos()
        //}
    }, [logged])

    useEffect(() => {
        console.log("USE EFFECT", atendimentos, loadingAtendimentos)
        if (loadingAtendimentos == true || atendimentos.length > 0) {
            for (let atendimento of atendimentos) {
                console.log("join_room", `chat:${atendimento.sessao_uuid}`)
                socket.emit("join_room", `chat:${atendimento.sessao_uuid}`)
            }
        }
    }, [atendimentos, loadingAtendimentos])

    const buscarAtendimentos = async () => {
        try {
            setLoadingAtendimentos(() => { return true })
            console.log("buscarAtendimentos")
            let resultado = await getAtendimentos()
            console.log("ATENDIMENTOS", resultado)
            setAtendimentos(resultado)
            setLoadingAtendimentos(() => { return false })
        } catch (error) {
            console.error("ERRO buscarAtendimentos", error)
            setLoadingAtendimentos(() => { return false })
        }
    }

    const getAtendimento = (atendimento_id) => {
        let busca = atendimentos.filter(a => a.id == atendimento_id)
        return busca.length > 0 ? busca[0] : null
    }

    const excluirAtendimento = async (atendimento, enviarAPI = true) => {
        try {
            let atendimento_id = atendimento.id
            let uuid = atendimento.sessao_uuid
            if (enviarAPI)
                await encerrarAtendimento(atendimento_id)
            setAtendimentos((atendimentos) => atendimentos.filter(a => a.id != atendimento_id))
            socket.emit("leave_room", `chat:${uuid}`)
        } catch (error) {
            alert("Erro no encerramento")
        }
    }

    return (
        <AtendimentoContext.Provider value={{
            atendimentos, setAtendimentos,
            atendimentoAberto, setAtendimentoAberto,
            loadingAtendimentos, setLoadingAtendimentos,
            getAtendimento,
            excluirAtendimento
        }}>
            {children}
        </AtendimentoContext.Provider>
    )
}

export default AtendimentoContext
