import api from "./apiService"

const getSessionToken = async () => {
    try {
        let sessionToken = await localStorage.getItem("access_token")

        return sessionToken
    } catch (error) {
        throw error
    }
}


const getUsuario = async () => {
    try {
        const resultado = await api.get("/usuarios/sessao")

        console.log("{ getUsuario }", resultado)

        if (!resultado || !resultado.data) throw { status: 404 }

        return resultado.data
    } catch (error) {
        throw error
    }
}

const doLogin = async (dados) => {
    try {
        const resultado = await api.post("/usuarios/login", dados)

        console.log("RESULTADO LOGIN", resultado)

        localStorage.setItem("access_token", resultado.data.access_token)

        return resultado.data
    } catch (error) {
        console.error(error, error.response)
        throw error
    }
}

const doLogout = async () => {
    try {
        localStorage.removeItem("access_token")

        //document.location.reload(true)

        return true
    } catch (error) {
        console.error(error)
        throw error
    }
}

export { getSessionToken, getUsuario, doLogin, doLogout }