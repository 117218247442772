import styled from "styled-components/native"

export const Container = styled.View`
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background: #ffffff;
    shadow-opacity: 0.2;
    shadow-radius: 5px;
    shadow-color: #000000;
    shadow-offset: -5px 5px;
    elevation: 8;
    z-index: 3;
`