import React, { useState, createContext, useEffect } from 'react'

import { getSessionToken, getUsuario } from "../services/authService"

import socket from "../services/socketService"

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    const [logged, setLogged] = useState(false)
    const [sessionToken, setSessionToken] = useState(null)
    const [usuario, setUsuario] = useState(null)
    const [situacao, setSituacao] = useState(null)

    useEffect(() => {
        verificarLogin()
    }, [])

    const verificarLogin = async () => {
        try {
            let session_token = await getSessionToken()

            if (session_token) {
                let resultadoUsuario = await getUsuario()
                console.log("GET USUARIO", resultadoUsuario)

                // Registrar usuário na sala de socket de cada setor
                for (let setor of resultadoUsuario.setores) {
                    console.log(`setor:${setor.id}`)
                    socket.emit("join_room", `setor:${setor.id}`)
                }

                setUsuario(resultadoUsuario)

                setSessionToken(session_token)
                setLogged(true)
            } else {
                setSessionToken(null)
                setLogged(false)
            }
        } catch (error) {

        }
    }

    return (
        <AuthContext.Provider value={{
            logged, setLogged,
            sessionToken, setSessionToken,
            usuario, setUsuario,
            situacao, setSituacao
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
