import styled from "styled-components/native"

export const Container = styled.View`
    font-size: 15px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    height: 100%;
    position: relative;
    padding-left: 20;
    padding-right: 20;
`

export const Dropdown = styled.View`
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 70px;
    background: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    shadow-opacity: 0.2;
    shadow-radius: 5px;
    shadow-color: #000000;
    shadow-offset: -5px 5px;
    elevation: 8;
    min-width: 100%;
`