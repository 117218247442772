import React, { useContext } from 'react';
import { View } from "react-native"

import Navigation from './src';

import Providers from './src/providers';

import styles from "./styles"

export default function App() {
  return (
    <Providers>
      <View style={styles.container}>
        <Navigation />
      </View>
    </Providers>
  );
}