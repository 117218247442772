import styled from "styled-components/native"

export const Container = styled.View`
    font-size: 15px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    height: 100%;
    position: relative;
`

export const Circulo = styled.View`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 10px;
`

export const Situacao = styled.TouchableOpacity`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding: 20px;
    width: 100%;
`